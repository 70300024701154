import React from 'react';
import Cta from '../Cta';
import Img from 'gatsby-image';
import { Link } from 'gatsby';

import styles from '../styles/CollectionList.module.scss';
import WidthLimiter from '../WidthLimiter';
import Section from './Section';
import HeroHeading from '../HeroHeading';

const CollectionList = ({ module }) => {
  return (
    <Section module={module}>
      <WidthLimiter>
        {module.heading && <HeroHeading text={module.heading} />}
        <div className={styles.grid}>
          {module.collections.map((c) => (
            <div className={styles.item} key={c.id}>
              <Link to={`/collections/${c.slug}`}>
                <Img fluid={c.gallery[0].preview} />
                <div className={styles.itemOverlay}>
                  <span className={styles.itemOverlayText}>{c.title}</span>
                </div>
              </Link>
            </div>
          ))}
        </div>
        {module.showCta && (
          <Cta text="See all collections" link="/collections" />
        )}
      </WidthLimiter>
    </Section>
  );
};

export default CollectionList;
