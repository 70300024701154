import React, { useState } from 'react';
import Cta from './Cta';

const ShowMore = ({ renderItem, count, items, renderContainer }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const canExpand = !isExpanded && count < items.length;
  const visibleCount = isExpanded ? items.length : count;
  const visibleItems = items.slice(0, visibleCount);
  const renderedItems = visibleItems.map(renderItem);

  renderContainer = renderContainer || (() => <div>renderedItems</div>);

  return (
    <>
      {renderContainer(renderedItems)}
      {canExpand && (
        <Cta onClick={() => setIsExpanded(true)} text="Show More" />
      )}
    </>
  );
};

export default ShowMore;
