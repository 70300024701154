import React from 'react';

import ShowMore from '../ShowMore';
import WidthLimiter from '../WidthLimiter';

import styles from '../styles/VideoEmbeds.module.scss';
import Section from './Section';
import HeroHeading from '../HeroHeading';

const VideoEmbeds = ({ module }) => {
  return (
    <Section module={module}>
      <WidthLimiter>
        {module.heading && <HeroHeading text={module.heading} />}
        <ShowMore
          items={module.videoLinks}
          count={2}
          renderItem={(item, index) => {
            return (
              <div className={styles.item} key={index}>
                <div className={styles.playerSpacer}>
                  <div className={styles.playerWrapper}>
                    <iframe
                      className={styles.player}
                      src={item}
                      frameBorder={0}
                      allow="fullscreen"
                    ></iframe>
                  </div>
                </div>
              </div>
            );
          }}
          renderContainer={(items) => (
            <div className={styles.container}>{items}</div>
          )}
        />
      </WidthLimiter>
    </Section>
  );
};

export default VideoEmbeds;
