import React from 'react';
import c from 'classnames';

import styles from '../styles/BigVideo.module.scss';

const BigVideo = (props) => {
  const canAutoPlay =
    typeof window === 'undefined' ? true : window.innerWidth > 900;
  const videoClassName = c(styles.video, { [styles.autoplay]: canAutoPlay });
  const videoProps = canAutoPlay
    ? {
        autoPlay: true,
        muted: true,
        loop: true,
        controls: false,
      }
    : {
        preload: 'metadata',
        controls: true,
        controlsList: 'nodownload nofullscreen noremoteplayback',
      };

  return (
    <div className={styles.wrapper}>
      <video
        className={videoClassName}
        src={props.module.videoSource.file.url}
        playsInline={true}
        {...videoProps}
      />
    </div>
  );
};

export default BigVideo;
