import React, { useEffect, useState } from 'react';

import CollectionGallery from '../CollectionGallery';
import ImageGrid from '../ImageGrid';

export default ({ module }) => {
  const [maxPhotos, setMaxPhotos] = useState(module.maxPhotos);

  useEffect(() => {
    if (window.matchMedia('screen and (max-width: 900px)').matches) {
      setMaxPhotos(Math.min(6, module.maxPhotos));
    }
  }, []);

  return (
    <>
      {module.collection && (
        <CollectionGallery collection={module.collection} slice={maxPhotos} />
      )}
      {module.photos && module.photos.length && (
        <ImageGrid images={module.photos} />
      )}
    </>
  );
};
