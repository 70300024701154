import React from 'react';
import { Link } from 'gatsby';

import NavbarLinks from './NavbarLinks';
import WidthLimiter from './WidthLimiter';

import navbarLogo from '../img/navbar-logo.svg';

import styles from './styles/Navbar.module.scss';

const Navbar = ({ location }) => {
  return (
    <div className={styles.wrapper}>
      <WidthLimiter>
        <nav className={styles.navbar}>
          <div className={styles.brandwrap}>
            <div className={styles.brandtop}></div>
            <div className={styles.brandbottom}>
              <Link to="/" className={styles.logoLink}>
                <img
                  src={navbarLogo}
                  alt="Josefina Bakošová"
                  className={styles.logo}
                />
              </Link>
            </div>
          </div>
          <div className={styles.navigation}>
            <NavbarLinks styles={styles} location={location} />
          </div>
        </nav>
      </WidthLimiter>
    </div>
  );
};

export default Navbar;
