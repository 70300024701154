import React from 'react';

import Archive from './Archive';
import BigVideo from './BigVideo';
import CollectionList from './CollectionList';
import Copy from './Copy';
import Gallery from './Gallery';
import VideoEmbeds from './VideoEmbeds';

const mapping = {
  ContentfulLayoutArchive: Archive,
  ContentfulLayoutBigVideo: BigVideo,
  ContentfulLayoutCollectionList: CollectionList,
  ContentfulLayoutCopy: Copy,
  ContentfulLayoutGallery: Gallery,
  ContentfulLayoutVideoEmbeds: VideoEmbeds,
};

export default function ContentModules({ modules }) {
  return (
    <>
      {modules.map((m) => {
        const Component = mapping[m.__typename];

        if (!Component) {
          throw new Error(
            `Content module for type ${m.__typename} not registered.`
          );
        }

        return <Component module={m} key={m.id} />;
      })}
    </>
  );
}
