import React from 'react';
import { Link } from 'gatsby';

import HeroHeading from '../HeroHeading';
import Section from './Section';
import WidthLimiter from '../WidthLimiter';

import styles from '../styles/Archive.module.scss';

export default ({ module }) => {
  return (
    <Section module={module}>
      <WidthLimiter>
        <div className={styles.archive}>
          {module.heading && <HeroHeading text={module.heading} />}
          <ul className={styles.list}>
            {module.collections.map((c) => (
              <li className={styles.item} key={c.id}>
                <Link to={`/collections/${c.slug}`}>
                  {c.year} - {c.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </WidthLimiter>
    </Section>
  );
};
