import React, { useEffect } from 'react';

import Cta from '../Cta';
import HeroHeading from '../HeroHeading';
import HTMLContent from '../HTMLContent';
import Section from './Section';
import WidthLimiter from '../WidthLimiter';

import styles from '../styles/Copy.module.scss';

export default ({ module }) => {
  const copyRef = React.createRef();

  useEffect(() => {
    const iframes = copyRef.current.getElementsByTagName('iframe');
    const resizeHandler = () => {
      iframes &&
        Array.from(iframes).forEach((iframe) => {
          iframe.style.height = `${(iframe.offsetWidth * 9) / 16}px`;
        });
    };

    resizeHandler();

    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  });
  return (
    <Section module={module}>
      <WidthLimiter>
        <div className={styles.copy} ref={copyRef}>
          {module.heading && <HeroHeading text={module.heading} />}
          <HTMLContent html={module.copy.childMarkdownRemark.html} />
          {module.ctaLink && (
            <Cta href={module.ctaLink} text={module.ctaTitle} />
          )}
        </div>
      </WidthLimiter>
    </Section>
  );
};
